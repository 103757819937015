import InvoicesContent from '..';
import InvoicesMultipointContent from "./InvoicesMultipointContent";

import { features } from "../../../settings";
import { Tabs, Tab, Box } from '@mui/material';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';

import GasIcon from '../../../assets/GasIcon';
import LightIcon from '../../../assets/LightIcon';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const InvoicesTabs = (props) => {
  const { t } = useTranslation();
  
  const settings = useSelector((state) => state.settings.data);

  const [invoiceType, setInvoiceType] = useState(settings?.showInvoicesMultipunt ? "multipoint" : features.displayTypes[0]); // 'electricity', 'gas',  'multipoint'

  const handleChangeInvoiceType = (event, value) => {
    setInvoiceType(value);
  };

  return <Box sx={{ width: '100%', marginTop: -2 }}>
    {features.displayTypes.length > 1 || settings?.showInvoicesMultipunt ? <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={invoiceType} onChange={handleChangeInvoiceType}>
          {settings?.showInvoicesMultipunt && <Tab label={t("common:text.contracts_tab_title_multipoint")} value="multipoint" icon={<DeviceHubIcon />}/>}
          {features.displayTypes.includes('electricity') && <Tab label={t("common:text.contracts_tab_title_electricty")} value="electricity" icon={<LightIcon />}/>}
          {features.displayTypes.includes('gas') && <Tab label={t("common:text.contracts_tab_title_gas")} value="gas" icon={<GasIcon />}/>}
        </Tabs>
      </Box>
      <Box sx={{mt: 2}}>
        {settings?.showInvoicesMultipunt && <div hidden={invoiceType !== 'multipoint'}><InvoicesMultipointContent/></div>}
        {features.displayTypes.includes('electricity') && <div hidden={invoiceType !== 'electricity'}><InvoicesContent type="electricity"/></div>}
        {features.displayTypes.includes('gas') && <div hidden={invoiceType !== 'gas'}><InvoicesContent type="gas"/></div>}
      </Box>
    </> : <>
      {settings?.showInvoicesMultipunt && <InvoicesMultipointContent/>}
      {features.displayTypes[0] === 'electricity' && <InvoicesContent type="electricity" />}
      {features.displayTypes[0] === 'gas' && <InvoicesContent type="gas" />}
    </>}
  </Box>;

}

export default InvoicesTabs;